.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.transportContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .transportLabel {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--body);
  }

  .transportOptions {
    display: flex;
    flex-wrap: wrap;
    gap: 12px 24px;
  }
}

.checkboxWrapper {
  min-width: 248px;

  .checkbox {
    width: fit-content;
  }
}

.distanceWrapper {
  display: flex;
  width: 100%;

  .field {
    width: 100%;
  }
}

.monetaryVolumesWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .leftWrapper {
    width: 100%;
    max-width: 50%;
    gap: 16px;
    display: flex;
    align-items: flex-end;
  }

  .rightWrapper {
    width: 100%;
    max-width: 50%;
    gap: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.fieldSelect {
  width: 100%;
  max-width: 130px;
}

.fieldTextField {
  width: 100%;
  max-width: 234px;
}