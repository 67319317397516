.container {
  width: 262px;
  min-height: 800px;
  padding: 16px;
  border: 1px dashed var(--system-borders);
  display: flex;
  justify-content: center;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  &.dragTarget {
    opacity: 0.6; border: 4px dashed var(--body);
  }
  .title {
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    max-width: 220px;
    text-align: center;
    height: fit-content;
    cursor: pointer;
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.isHovered {
  background-color: var(--system-background);
}
