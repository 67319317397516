.modalOverlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: var(--system-background-light);
  border-radius: 8px;
  padding: 24px;
  width: 888px;
  height: calc(100vh - 100px);
  max-height: 757px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  position: relative;
}

.modalTitle {
  color: var(--body);
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--system-borders);
  margin-bottom: 24px;
  flex-shrink: 0;
}

.modalBody {
  color: var(--body-light);
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 24px;
  overflow-y: auto;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  padding-top: 0px;
  flex-shrink: 0;

  & > button {
    width: 110px;
    height: 32px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
} 