.customNode {
  min-width: 300px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: var(--system-background-light);
  box-shadow: 0 2.49px 3.735px 0 rgba(0, 0, 0, 0.09);
  &.dragTarget {
    opacity: 0.6; border: 2px dashed var(--body);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  position: relative;
  &.JV {
    background-color: var(--profit-center-jv);
  }
  &.Subsidiary {
    background-color: var(--profit-center-subsidary);
  }
  &.AssociateSubsidiary {
    background-color: var(--profit-center-associate-sub);
  }
  &.BusinessUnit {
    background-color: var(--profit-center-business-unit);
  }
  &.Division {
    background-color: var(--profit-center-division);
  }
  &.disabled {
    background-color: var(--profit-center-disabled);
    svg {
      color: var(--profit-center-disabled-text);
    }
  }
  .titleWrapper {
    display: flex;
    gap: 6px;
    align-items: center;
  }
  input {
    cursor: pointer;
    width: 7px;
    height: 7px;
  }
  p {
    color: var(--body);
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    &.disabled {
      color: var(--profit-center-disabled-text);
    }
  }
  .activeWrapper {
    display: flex;
    gap: 4px;
    align-items: center;
    .info {
      width: 16px;
      height: 16px;
      padding: 0;
      border-radius: 4px;
      background-color: transparent;
      svg {
        color: var(--warning);
      }
    }
    .checkbox {
      width: 16px;
      height: 16px;
    }
    .percentage {
      padding: 4px 6px;
      background-color: #00D1FF;
      border-radius: 60px;
      align-items: center;
      justify-content: center;
      display: flex;
      &.JV {
        background-color: var(--profit-center-jv-percent);
      }
      &.Subsidiary {
        background-color: var(--profit-center-subsidary-percent);
      }
      &.AssociateSubsidiary {
        background-color: var(--profit-center-associate-sub-percent);
      }
      &.BusinessUnit {
        background-color: var(--profit-center-business-unit-percent);
      }
      &.Division {
        background-color: var(--profit-center-division-percent);
      }
      &.disabled {
        background-color: var(--profit-center-disabled-text-percent);
      }
      .percentageText {
        color: var(--system-background-light);
        font-size: 10px;
        line-height: 10px;
        font-weight: 600;
      }
    }
  }
}

.content {
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 6px
}

.customNodeItem {
  .title {
    color: var(--body-light);
    font-size: 8px;
    font-weight: 400;
    line-height: 10px;
    text-transform: uppercase;
    &.disabled {
      color: var(--profit-center-disabled-text);
    }
    &.capitalize {
      text-transform: capitalize;
    }
  }
  .value {
    color: var(--body);
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-transform: uppercase;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    &.disabled {
      color: var(--profit-center-disabled-text);
    }
    &.capitalize {
      text-transform: capitalize;
    }
  }
}
