.rightCenterPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin: 0;
  padding-right: 20px;
}

.colorsTip {
  padding-left: 80px;
  z-index: -1;
  &.fullScreen {
    padding-left: 0;
  }
}

.fullLoader {
   left: 0;
   top: 0;
 }

.profitCenterWrapper {
  position: absolute;
  bottom: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 16px 8px;
  min-width: 92px;
  background-color: var(--system-background-light);
  border: 1px solid var(--system-background);
  border-radius: 6px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);
  cursor: pointer;
  &:hover {
    background-color: var(--system-background);
  }
  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: var(--body-light);
  }
}
