.container {
  display: flex;
  gap: 12px;
  .zoomControls {
    display: flex;
    background-color: var(--system-background-light);
    align-items: center;
    border-radius: 40px 40px;
    cursor: pointer;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);
    .divider {
      width: 2px;
      background-color: var(--system-borders);
      height: 36px;
    }
  }
  .btn {
    display: flex;
    background-color: var(--system-background-light);
    padding: 6px;
    border: 0;
    border-radius: 40px 0 0 40px;
    cursor: pointer;
    svg {
      color: var(--body);
    }
    &.plus {
      border-radius: 0 40px 40px 0;
    }
    &:hover {
      background-color: var(--system-text-background);
      cursor: pointer;
    }
    &:active {
      background-color: var(--system-background);
    }
    &.disabled {
      background-color: var(--system-background);
      opacity: 0.3;
      cursor: not-allowed;
    }
    &.fullscreenBtn {
      border-radius: 40px;
      justify-content: center;
      align-items: center;
    }
  }
}
