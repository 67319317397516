.separator {
  margin: 0;
}

.archived {
  td {
    color: var(--body-disabled)!important;
    label {
      color: var(--body-disabled)!important;
    }
  }
}

.staticCell {
  display: flex;
}

.suppliersList {
  list-style: none;
}
