.root {
  width: 100%;
  min-height: 100vh;

  .content {
    display: flex;
    height: 100%;
    min-height: calc(100vh - 90px);

    .main {
      flex: 1;
      overflow: hidden;
      padding: 24px;
      &.noPadding {
        padding: 0;
      }
    }
  }
}
