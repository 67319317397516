.reactFlow {
  .react-flow__edges {
    z-index: 1;
    cursor: pointer;
  }
  .react-flow__attribution {
    display: none;
  }
}

.reactFlowBusiness {
  .react-flow__attribution {
    display: none;
  }
} 