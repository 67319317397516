.nameCell {
  width: 30%;
  padding: 0;
}

.client {
  display: flex;
  align-items: center;
  gap: 16px;
  text-decoration: none;

  &Button {
    cursor: pointer;
    background: none;
    border: none;
    text-align: start;
    width: 100%;
    padding: 24px;
  }

  &Name {
    max-width: 80%;
    font-weight: 700;
    line-height: 24px;
    color: var(--body);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
  }
}

.adminName {
  color: var(--body-light);
}

.status {
  display: flex;
  align-items: center;
  gap: 10px;

  .info {
    padding: 0;
    background-color: transparent;
  }
}
.adminNames {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.processing {
  cursor: auto;
}