.root {
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px 4px 4px 32px;
  font-size: 14px;
  color: var(--body);
  user-select: none;
  gap: 8px;
  cursor: pointer;
}

.root input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: var(--system-background-light);
  border: 1px solid var(--primary);
  border-radius: 4px;
  transform: translateY(-50%);
}

.radio {
  @extend .checkmark;
  border-radius: 100%;
  background-color: transparent;
  border-color: var(--primary);
}

.root:hover input + .checkmark {
  background-color: var(--system-background);
}

.root input:checked + .checkmark {
  background-color: var(--system-background-light);
}

.root input:checked + .checkmark:not(.squareMark) {
  background-color: var(--primary);
}

.root input:checked + .squareMark {
  background-color: var(--system-background-light);
}

.root input:checked + .radio {
  background-color: transparent;
}

.root input:disabled + .checkmark {
  background-color: var(--primary);
  border-color: var(--primary);
  opacity: 0.6;
}

.checkmark:after {
  content: "";
  position: absolute;
}

.root input:checked + .checkmark:after {
  transform: scale(1) rotate(45deg);
}

.root input:checked + .lineMark:after {
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  background-color: var(--system-background-light);
  border: none;
  width: 56%;
  height: 2px;
}

.root .checkmark:after {
  left: 7px;
  top: 1px;
  width: 9px;
  height: 16px;
  border: solid var(--system-background-light);
  border-width: 0 2px 2px 0;
  border-radius: 1px;
  transform: scale(0) rotate(45deg);
  transition: transform 300ms ease;
}

.root .lineMark:after {
  transform: none;
  transition: none;
}

.root input:checked + .squareMark:after {
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: var(--primary);
  border: none;
}

.root .squareMark:after {
  transform: none;
  transition: none;
}

.root .radio:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: var(--primary);
  border: none;
  top: 3px;
  left: 3px;
}

.icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
    font-size: 16px;
  }
}
