.button-edge__icon {
  width: 30px;
  height: 30px;
  background-color: var(--system-background-light);
  z-index: 10;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);
}

.button-edge__wrapper {
  position: absolute;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  max-width: 60px;
}

.button-edge__connectionLine {
  stroke-width: 2px;
  stroke-dasharray: 5 5;
  &.logical {
    stroke: var(--value-chain-connection-logical);
  }
  &.financial {
    stroke: var(--value-chain-connection-financial);
  }
  &.service {
    stroke: var(--value-chain-connection-service);
  }
  &.supplierOfGoods {
    stroke: var(--value-chain-connection-supplier-of-goods);
  }
}
