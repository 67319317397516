.container {
  list-style-type: none;

  li {
    .actionWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      padding: 16px 8px;
      min-width: 92px;
      background-color: var(--system-background-light);
      border: none;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);
      cursor: grab;
      &:hover {
        background-color: var(--system-background);
      }
      &:active {
        cursor: grabbing;
      }
      svg {
        color: var(--color-icon-dark-grey);
      }
      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: var(--body-light);
        max-width: 76px;
      }
    }
    &:first-child {
      margin-bottom: 16px;
      .actionWrapper {
        border-radius: 6px;
      }
    }
    &:nth-child(2) {
      .actionWrapper {
        border-radius: 6px 6px 0 0 ;
      }
    }
    &:last-child {
      .actionWrapper {
        border-radius: 0 0 6px 6px ;
      }
    }
  }
}

.aloneElement {
  li {
    .actionWrapper {
      &:last-child {
        border-radius: 6px;
      }
    }
  }
}

.redDot {
  position: absolute;
  top: 12px;
  right: 30px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--error);
}