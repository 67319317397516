.centerPanel {
  margin-left: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  .switcher {
    display: flex;
    padding: 4px;
    border-radius: 6px;
    background-color: var(--system-background-light);
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);
  }
}

