.root {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 880px;
  padding: 22px;
  background-color: var(--system-background-light);
  border: 1px solid var(--system-borders);
  border-radius: 8px;
  transform: translate(-50%, -50%);
  height: fit-content;
  margin-top: 50px;

  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: var(--body);
  }

  .wrapperContainer {
    overflow: auto;
    max-height: calc(100vh - 366px);
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    .wrapperForm {
      width: 100%;
      display: flex;
      flex-direction: column;
      border: 1px solid var(--system-borders);
      border-radius: 8px;
      margin-bottom: 24px;
      padding-bottom: 24px;
  
      .headerForm {
        background: var(--secondary-light);
        width: 100%;
        padding: 24px;
        border-radius: 8px 8px 0 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
  
        .headerWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          font-weight: 600;
          color: var(--body);
          padding-left: 50px;
          gap: 10px;
    
          div {
            flex: 1;
          }
        }
  
        .containerHeader {
          display: flex;
          align-items: center;
          margin-top: 6px;
  
          .numberContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: var(--primary);
            margin-right: 14px;
  
            .number {
              font-size: 14px;
              font-weight: 600;
              color: var(--system-background-light);
            }
          }
  
          .arrow {
            display: flex;
            align-items: center;
            padding: 0 16px;
        
            svg {
              transform: rotate(-90deg);
              color: var(--subtitle);
            }
          }
  
          .field {
            flex: 0.5;
          }
        }
      }
    }
  
    .content {
      padding: 0 24px;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
        font-size: 14px;
        font-weight: 600;
        color: var(--body);
        padding-left: 50px;
        gap: 10px;
  
        div {
          flex: 1;
        }
      }
  
      .wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        gap: 22px;
        padding: 0 2px;
      }
    }
  }

  .footer {
    display: flex;
    gap: 10px;
    margin-top: 24px;
    justify-content: space-between;

    .progressWrapper {
      display: flex;
      gap: 16px;
      align-items: center;

      .progressBar {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background:
          radial-gradient(closest-side, var(--system-background-light) 79%, transparent 80% ),
          conic-gradient(from 0deg, var(--status-active) var(--percentage, 100%), var(--system-borders) 0);
      }

    }

    .buttons {
      display: flex;
      gap: 10px;
      min-width: 250px;

      & > button {
        flex-grow: 1;
        flex-basis: 50%;
      }
    }

  }
}

.mappingItem {
  display: flex;
  flex: 0.5;

  .status {
    display: flex;
    align-items: center;
    padding: 0 14px 0 8px;
  }

  .field {
    flex: 0.5;
  }

  .placeholder {
    font-weight: 400;
    color: var(--error);
  }

  .arrow {
    display: flex;
    align-items: center;
    padding: 0 16px;

    svg {
      transform: rotate(-90deg);
      color: var(--subtitle);
    }
  }

}

.paddingLeft {
  padding-left: 45px;
}

.menuMultiple {
  position: absolute !important;
}