$black: #000000;
$white: #FFFFFF;
$firefly: #07272D;
$morningBlue: #8EA4A6;
$charcoal: #363A3A;
$carbonGrey: #5A5F60;
$milkWhite: #DBDDDE;
$culturedPearl: #F3F4F4;
$deepAquamarine: #115E67;
$paleAqua: #C9E1E5;
$carbonGreyAlpha: #5A5F6050;
$tealBlue: #008C95;
$lovelyPurple: #7F2BED;
$brightSkyBlue: #00D1FF;
$greenOnion: #7A9A01;
$azul: #0555F0;
$red: #DC2626;
$persianPink: #FF76D0;
$orange: #FFAD08;
$topaz: #08BF9E;
$richBlack: #0F3F45;
$darkGrey: #363A3A;
$aquaHaze: #EDF5F6;
$blackAlpha: #0000001F;
$infoYellow: #F89603;
$yourPink: #FFC7C2;
$cherokee: #FCD19C;
$frenchPass: #BCE3FF;
$fog: #E4CCFF;
$periwinkle: #D0DBFF;
$blackHaze: #E7E9E9;
$seashell: #F1F1F1;
$silverSand: #B5B9BA;
$vividTangerine: #FF9188;
$koromiko: #FFB65D;
$malibu: #6EC3FF;
$heliotrope: #BE84FF;
$malibuDark: #87A3FF;
$dodgerBlue: #467AFF;
$woodSmoke: #121113;
$malachite: #07BD2C;
$california: #FC9709;
$gossip: #BDF6A7;
$milan: #F6FD9D;
$lavenderRose: #FDB3FB;
$mercury: #E4E4E4;
$electricFuchsia: #CE01A8;
$sunsetFlame: #FF8C00;

html[data-theme="light"] {
  --headings1: #{$firefly}; // dinamic
  --headings2: #{$firefly}; // dinamic
  --subtitle: #{$morningBlue}; // dinamic
  --primary: #{$deepAquamarine}; // dinamic
  --primary-light: color-mix(in srgb, var(--system-background-light) 100%, var(--primary) 10%); // dinamic
  --primary-dark: color-mix(in srgb, var(--system-background-dark) 30%, var(--primary) 100%); // dinamic
  --primary-disabled: color-mix(in srgb, var(--system-background-light) 70%, var(--primary));
  --secondary: #{$paleAqua}; // dinamic
  --secondary-light: color-mix(in srgb, var(--system-background-light) 100%, var(--secondary) 40%); // dinamic
  --body: #{$charcoal};
  --body-light: #{$carbonGrey};
  --body-disabled: color-mix(in srgb, var(--system-background-light) 50%, var(--body));
  --system-borders: #{$milkWhite};
  --system-background: #{$culturedPearl};
  --system-background-light: #{$white};
  --system-background-dark: #{$black};
  --system-backdrop: #{$carbonGreyAlpha};
  --system-hint: #{$richBlack};
  --system-text-background: #{$blackHaze};

  --error: #{$red};
  --error-dark: color-mix(in srgb, var(--system-background-dark) 30%, var(--error) 100%);
  --warning: #{$orange};
  --success: #{$tealBlue};

  --status-dimmed-disabled: #{$lovelyPurple};
  --status-visible-enabled: #{$brightSkyBlue};
  --status-active: #{$greenOnion};
  --status-invited: #{$azul};
  --status-disabled: #{$red};
  --status-draft: #{$milkWhite};
  --status-view-audit: #{$persianPink};
  --status-view-edit: #{$orange};
  --status-view-only: #{$topaz};
  --status-dropdown-shadow: #{$blackAlpha};
  --status-approved: #{$deepAquamarine};
  --status-request-approval: #{$electricFuchsia};
  --status-requires-rework: #{$sunsetFlame};

  --color-icon-dark-grey: #{$darkGrey};
  --table-header-color: #{$aquaHaze};
  --color-icon-yellow: #{$infoYellow};

  --profit-center-subsidary: #{$yourPink};
  --profit-center-jv: #{$cherokee};
  --profit-center-associate-sub: #{$frenchPass};
  --profit-center-business-unit: #{$fog};
  --profit-center-division: #{$periwinkle};
  --profit-center-disabled: #{$seashell};
  --profit-center-disabled-text: #{$silverSand};
  --profit-center-subsidary-percent: #{$vividTangerine};
  --profit-center-jv-percent: #{$koromiko};
  --profit-center-associate-sub-percent: #{$malibu};
  --profit-center-business-unit-percent: #{$heliotrope};
  --profit-center-division-percent: #{$malibuDark};
  --profit-center-disabled-text-percent: #{$silverSand};

  --value-chain-connection-logical: #{$dodgerBlue};
  --value-chain-connection-financial: #{$woodSmoke};
  --value-chain-connection-supplier-of-goods: #{$malachite};
  --value-chain-connection-service: #{$california};
  --value-chain-unit-supplier: #{$gossip};
  --value-chain-unit-inputProduct: #{$milan};
  --value-chain-unit-ownOperation: #{$fog};
  --value-chain-unit-profitCenter: #{$periwinkle};
  --value-chain-unit-customer: #{$lavenderRose};
  --value-chain-unit-estimated: #{$mercury};
}

html[data-theme="dark"] {
  --headings1: #{$firefly}; // dinamic
  --headings2: #{$firefly}; // dinamic
  --subtitle: #{$morningBlue}; // dinamic
  --primary: #{$deepAquamarine}; // dinamic
  --primary-light: color-mix(in srgb, var(--system-background-light) 100%, var(--primary) 10%); // dinamic
  --primary-dark: color-mix(in srgb, var(--system-background-dark) 30%, var(--primary) 100%); // dinamic
  --secondary: #{$paleAqua}; // dinamic
  --secondary-light: color-mix(in srgb, var(--system-background-light) 100%, var(--secondary) 40%); // dinamic
  --body: #{$charcoal};
  --body-light: #{$carbonGrey};
  --system-borders: #{$milkWhite};
  --system-background: #{$culturedPearl};
  --system-background-light: #{$white};
  --system-background-dark: #{$black};
  --system-backdrop: #{$carbonGreyAlpha};
  --system-hint: #{$richBlack};
  --system-text-background: #{$blackHaze};

  --error: #{$red};
  --error-dark: color-mix(in srgb, var(--system-background-dark) 30%, var(--error) 100%);
  --warning: #{$orange};
  --success: #{$tealBlue};

  --status-dimmed-disabled: #{$lovelyPurple};
  --status-visible-enabled: #{$brightSkyBlue};
  --status-active: #{$greenOnion};
  --status-invited: #{$azul};
  --status-disabled: #{$red};
  --status-draft: #{$milkWhite};
  --status-view-audit: #{$persianPink};
  --status-view-edit: #{$orange};
  --status-view-only: #{$topaz};
  --status-approved: #{$deepAquamarine};
  --status-request-approval: #{$electricFuchsia};
  --status-requires-rework: #{$sunsetFlame};

  --profit-center-subsidary: #{$yourPink};
  --profit-center-jv: #{$cherokee};
  --profit-center-associate-sub: #{$frenchPass};
  --profit-center-business-unit: #{$fog};
  --profit-center-division: #{$periwinkle};
  --profit-center-disabled: #{$seashell};
  --profit-center-disabled-text: #{$silverSand};
  --profit-center-subsidary-percent: #{$vividTangerine};
  --profit-center-jv-percent: #{$koromiko};
  --profit-center-associate-sub-percent: #{$malibu};
  --profit-center-business-unit-percent: #{$heliotrope};
  --profit-center-division-percent: #{$malibuDark};
  --profit-center-disabled-text-percent: #{$silverSand};

  --value-chain-connection-logical: #{$dodgerBlue};
  --value-chain-connection-financial: #{$woodSmoke};
  --value-chain-connection-supplier-of-goods: #{$malachite};
  --value-chain-connection-service: #{$california};
  --value-chain-unit-supplier: #{$gossip};
  --value-chain-unit-inputProduct: #{$milan};
  --value-chain-unit-ownOperation: #{$fog};
  --value-chain-unit-profitCenter: #{$periwinkle};
  --value-chain-unit-customer: #{$lavenderRose};
  --value-chain-unit-estimated: #{$mercury};
}
