.rightPanel {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  flex: 0 0 auto;
  .rightContent {
    display: flex;
    gap: 8px;
    align-items: center;
    background-color: var(--system-background-light);
    padding: 4px 16px;
    height: 48px;
    border-radius: 6px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);
    .icon {
      background-color: var(--system-background-light);
      border: 0;
      svg {
        color: #363A3A;
      }
    }
    .undoIcon {
      @extend .icon;
      transform: scaleX(-1);
      &.disabled {
        color: #B5B9BA;
      }
    }
    .saveContainer {
      display: flex;
      gap: 8px;
      .checkMarkContainer {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: var(--headings2);
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          color: var(--system-background-light);
        }
      }
      .saveTitle {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .divider {
    width: 1px;
    height: 28px;
    background-color: var(--system-borders);
  }
}
