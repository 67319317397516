.container {
  width: 424px;
  height: calc(100vh - 90px);
  background-color: var(--system-background-light);
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 9;
  box-shadow: -10px 0 10px -5px var(--system-backdrop);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.open {
    transform: translateX(0);
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .headerFilters {
      width: 100%;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--system-borders);
  
      & > button {
        padding: 0;
      }
  
      .headerText {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: var(--primary);
      }
    }

    .searchInputWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 16px;
    }

    .filters {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 16px;

      .buttonWrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .filterButton {
          width: 100%;
          border-bottom: 1px solid var(--system-borders);
          padding: 18px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: var(--body);
          border-radius: 0;
  
          svg {
            rotate: -90deg;
            color: var(--body);
          }
        }
      }

      .filterWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 8px 0;

        &:first-child {
          padding-top: 0;
        }
      }
    }
  }
}

.buttons {
  display: flex;
  gap: 16px;
  min-width: 250px;
  padding: 24px 16px;

  & > button {
    flex-grow: 1;
    flex-basis: 50%;
    max-height: 36px;
  }
}

.circleCounter {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--primary);
  color: var(--system-background-light);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  top: 20px;
  right: 30px;
}

.contentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px 0;

  .tabsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  
    .tab {
      cursor: pointer;
      color: var(--body);
      border-bottom: 1px solid var(--system-borders);
      width: 100%;
      position: relative;
    }
  
    .tab.active {
      color: var(--primary);
      border-bottom: 1px solid var(--primary);
    }

    button {
      border-radius: 0;
      padding: 16px 0 16px 28px;
      display: flex;
      justify-content: flex-start;
    }
  }

  .tabContent {
    width: 100%;
    height: 550px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    gap: 8px;
    border-left: 1px solid var(--primary-light);
    margin-top: 16px;
    
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}