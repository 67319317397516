.unitContainer {
  border: none;
  border-radius: 8px;
  background-color: transparent;
  &.dragTarget {
    opacity: 0.6; border: 2px dashed var(--body);
  }
  .container {
    width: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    background-color: transparent;
    position: relative;
    &.childContainer {
      margin-left: 12px;
      width: 208px;
    }
    .statuses {
      display: flex;
      gap: 4px;
      margin-bottom: 6px;
      .supplier {
        background-color: var(--value-chain-unit-supplier);
      }
      .inputProduct {
        background-color: var(--value-chain-unit-inputProduct);
      }
      .own-operation {
        background-color: var(--value-chain-unit-ownOperation);
      }
      .profitCenter {
        background-color: var(--value-chain-unit-profitCenter);
      }
      .customer {
        background-color: var(--value-chain-unit-customer);
      }
      .estimated {
        background-color: var(--value-chain-unit-estimated);
      }
    }
    .unit {
      background-color: var(--system-background-light);
      border: 1px solid;
      border-radius: 8px;
      box-shadow: 0 2.49px 3.735px 0 rgba(0, 0, 0, 0.09);
      &.supplier {
        border-color: var(--value-chain-unit-supplier);
        .header {
          background-color: var(--value-chain-unit-supplier);
        }
      }
      &.product {
        border-color: var(--value-chain-unit-inputProduct);
        .header {
          background-color: var(--value-chain-unit-inputProduct);
        }
      }
      &.own-operation {
        border-color: var(--value-chain-unit-ownOperation);
        .header {
          background-color: var(--value-chain-unit-ownOperation);
        }
      }
      &.profit-center {
        border-color: var(--value-chain-unit-profitCenter);
        .header {
          background-color: var(--value-chain-unit-profitCenter);
        }
      }
      &.customer {
        border-color: var(--value-chain-unit-customer);
        .header {
          background-color: var(--value-chain-unit-customer);
        }
      }
      &.estimated {
        border-color: var(--value-chain-unit-estimated);
        .header {
          background-color: var(--value-chain-unit-estimated);
        }
      }
      .header {
        display: flex;
        padding: 8px 12px;
        border-radius: 6px 6px 0 0;
        gap: 8px;
        flex: 1;
        align-items: center;
        svg {
          width: 18px;
          height: 18px;
          color: var(--color-icon-dark-grey);
        }
        .title {
          font-size: 12px;
          font-weight: 600;
          text-wrap: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .content {
        padding: 8px 12px;
        display: flex;
        flex-direction: column;
        gap: 6px
      }
    }
  }

}
