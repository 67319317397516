.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.row {
  width: 100%;
  display: flex;
  gap: 48px;
  justify-content: space-between;
}

.volumesWrapper {
  width: 100%;
  max-width: 50%;
  gap: 16px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.fieldSelect {
  width: 100%;
  max-width: 380px;
}

.fieldSelectVolumes {
  width: 100%;
  max-width: 130px;
}
