.accordion {
  width: 100%;
  border: 1px solid var(--system-borders);
  border-radius: 8px;
}

.header {
  width: 100%;
  padding: 24px;
  background: var(--secondary-light);
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
  border-radius: 6px;

  &:hover {
    background-color: var(--secondary-light);
  }

  &:focus {
    outline: 2px solid var(--secondary-light);
    outline-offset: -2px;
  }

  &.expanded {
    background-color: var(--secondary-light);
    border-radius: 6px 6px 0 0;
  }
}

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--body);
}

.content {
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  background-color: var(--system-background-light);
  border-radius: 0 0 8px 8px;
}

.innerContent {
  padding: 16px;
  border-radius: 0 0 8px 8px;
} 

.buttonConnection {
  color: var(--primary);
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  gap: 10px;
  &:hover {
    background-color: transparent;
    opacity: 0.8;
  }
}