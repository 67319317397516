.listWrapper {
  grid-column: 1 / -1;
}

.listItem {
  display: flex;
  gap: 22px;

  &:not(:first-child) {
    margin-top: 24px;
  }
}

.select,
.selectId {
  flex: 1;
}

.selectId {
  display: flex;
  gap: 24px;
}
