.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .mainWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;

    .line {
      width: 16px;
      height: 1px;
      background-color: var(--primary-light);
    }
  
    .iconWrapper {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
  
      svg {
        color: var(--primary);
        rotate: -90deg;
        transition: rotate 0.3s ease-in-out;
      }

      &.active {
        svg {
          rotate: 0deg;
        }
      }
    }
  }
}
  
.accordion {
  width: 100%;
}

.content {
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  width: 100%;
}
  
.innerContent {
  width: 100%;
  padding: 0 16px 0 35px;
}

.contentWrapper {
  width: 100%;
  border-left: 1px solid var(--primary-light);
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content .innerContent .contentWrapper .content {
  position: static;
  
  .innerContent {
    position: static;
    width: 100%;
  }
}
