.container {
  background-color: var(--system-background-light);
  border-radius: 8px;
  padding: 24px 0;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);
  min-height: 900px;
  &.dragTarget {
    opacity: 0.6; border: 8px dashed var(--body);
  }
  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 24px;
    text-align: center;
  }
}
