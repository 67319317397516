.container {
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: flex;
  border-radius: 4px;
  p {
    font-size: 12px;
    font-weight: 600;
  }
}
