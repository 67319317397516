.modal {
  max-width: 888px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  position: relative;

  .fullRow {
    grid-column: 1 / -1;
    grid-row: 1;
  }

  .addNewWrapper, .wrapper {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .text {
    margin-bottom: 10px;
  }

  .OwnOperationModalFormWrapper {
    height: min(700px, 68vh);
    overflow: auto;
    margin-bottom: 24px;
    margin-top: 24px;
  }

  .profitCenterList {
    border: 2px solid var(--system-borders);
    margin-bottom: 30px;
    height: min(555px, 60vh);
    border-radius: 8px;
    padding: 8px 0;
    overflow: auto;

    .profitCenter {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      gap: 8px;
      transition: all 0.3s;
      cursor: pointer;
      font-weight: 400;
      color: var(--body);

      &.isSelected {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: var(--primary-disabled);
          opacity: 0.2;
          z-index: -1;
        }
      }

      .profitCenter_icon {
        color: var(--headings1);
        width: 16px;
        height: 16px;
      }

      &:hover {
        background-color: var(--primary-light);
      }
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .buttonsRight {
      display: flex;
      gap: 16px;
    }
  }

  .emptyList {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .stepCounter {
    display: flex;
    justify-content: space-between;
    margin: 24px auto;
    width: 420px;

    .divider {
      width: 125px;
      margin: 16px;
      height: 1px;
      background-color: var(--system-borders);

      &.completed {
        background-color: var(--primary);
      }
    }

    .stepCounterItem {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 400;
      color: var(--body);

      .counter {
        width: 32px;
        height: 32px;
        background-color: var(--system-background);
        color: var(--body-light);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      &.active {
        .counter {
          background-color: var(--primary);
          color: var(--system-background-light);
        }
      }

      &.completed {
        .counter {
          background-color: var(--primary);

          .icon {
            color: var(--system-background-light);
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
