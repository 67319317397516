
.container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  position: relative;
  width: 128px;

  .iconButton {
    border: none;
  }


  .moreActionsDropdown {
    position: absolute;
    right: 0;
    background-color: var(--system-background-light);
    z-index: 10;
    box-shadow: 0 2px 10px 0 var(--status-dropdown-shadow);
    border-radius: 8px;
    padding: 5px 0;

    &.down {
      top: calc(100% + 5px);
    }

    &.up {
      bottom: calc(100% + 5px);
    }

    .moreActionsListItem {
      padding: 8px 16px;
      cursor: pointer;
      display: flex;
      gap: 8px;
      align-items: center;
      transition: all 0.3s ease;

      &:hover {
        background-color: var(--primary-light);
      }

      .moreActionsText {
        font-size: 14px;
        line-height: 20px;
        color: var(--body);
      }
    }
  }
}
