.root {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 880px;
  padding: 22px 24px;
  background-color: var(--system-background-light);
  border: 1px solid var(--system-borders);
  border-radius: 8px;
  transform: translate(-50%, -50%);
  height: fit-content;
  margin-top: 50px;

  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: var(--body);
  }

  .content {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      font-size: 14px;
      font-weight: 600;
      color: var(--body);
      padding-left: 50px;
      gap: 10px;

      div {
        flex: 1;
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      gap: 22px;
      overflow: auto;
      max-height: calc(100vh - 375px);

      .errorItemList {
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-bottom: 1px solid var(--system-borders);
        padding-bottom: 16px;
        &:last-child {
          border-bottom: none;
        }

        .errorItemListTitle {
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 28px;
          font-weight: 600;
          color: var(--body-light);
          gap: 8px;
        }

        .errorItemWrapperListContent {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 8px;

          .errorItemListContent {
            display: flex;
            justify-content: space-between;
            gap: 16px;
  
            .errorLeftWrapper {
              display: flex;
              flex-direction: column;
              flex: 0.3;
  
              span {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                color: var(--body);
              }
            }
  
            .errorRightWrapper {
              display: flex;
              gap: 2px;
              flex: 0.6;
  
              .errorList {
                display: flex;
                flex-direction: column;
                gap: 2px;
                padding-top: 5px;
                span {
                  font-size: 16px;
                  line-height: 28px;
                  font-weight: 400;
                  color: var(--body-light);
                }
              }
            }
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    gap: 10px;
    margin-top: 24px;
    justify-content: space-between;

    .buttonWrapper {
      display: flex;
      gap: 10px;
      align-items: flex-end;
      flex-direction: column;
      flex: 1;

      & > button {
        min-width: 120px;
      }
    }

  }
}
