.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .panel {
    padding: 34px 24px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .header {
      font-size: 20px;
      font-weight: 600;
      color: var(--headings1);
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-left: 0;
      gap: 10px;

      .description {
        font-size: 16px;
        font-weight: 400;
        color: var(--subtitle);
        display: none;
      }
    }

    .separator {
      margin: 24px 0 14px;
    }
  }
}
