.root {
  display: flex;
  flex-direction: column;
  gap: 6px;

  .menuPortal {
    z-index: 10;
  }

  .label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: var(--body);
  }

  .control {
    padding: 2px 0;
    line-height: 28px;
    border-radius: 6px;
    border: 1px solid var(--system-borders);
    background-color: var(--system-background-light);
    box-shadow: none;

    &:hover {
      border: 1px solid var(--system-borders);
    }

    &Error {
      border: 1px solid var(--error);

      &:hover {
        border: 1px solid var(--error);
      }
    }

    &[aria-disabled] {
      background: var(--system-background);
      div{
        color: var(--body);
      }
    }
  }

  .separator {
    display: none;
  }

  .chevron {
    svg {
      width: 24px;
      height: 24px;
      color: var(--body);
    }
  }

  .menu {
    border-radius: 8px;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);
    background-color: var(--system-background-light);
    z-index: 10;
    > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 5px 0;
    }
  }

  .option {
    padding: 6px 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--body);
    background-color: unset;
    cursor: pointer;

    &:hover {
      background-color: var(--primary-light);
    }

    &Selected {
      color: var(--primary);
      background-color: var(--primary-light);
    }
  }

  .placeholder {
    color: var(--body);
    opacity: 0.7;
  }

  .error {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: var(--error);
  }
}

.customMultiValue {
  &:hover {
    word-wrap: anywhere;

   div {
     cursor: pointer;
     white-space: normal;
    }
  }
}

.customSingleValue {
  cursor: pointer;
  z-index: 9;
  &:hover {
    word-wrap: anywhere;
    white-space: normal;
  }
}
