.root {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 454px;
  padding: 24px;
  background-color: var(--system-background-light);
  border: 1px solid var(--system-borders);
  border-radius: 8px;
  transform: translate(-50%, -50%);
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 0;
  background-color: transparent;
}
.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.title {
  max-width: calc(100% - 55px);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: var(--body);
}

.description {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: var(--body-light);
}

.actions {
  display: flex;
  gap: 10px;
  margin-top: 24px;

  & > button {
    flex-grow: 1;
    flex-basis: 50%;
  }
}
