.root {
  display: flex;
  align-items: center;
  padding-bottom: 30px;

  .errorItemList {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid var(--system-borders);
    padding-bottom: 16px;
    width: 100%;
    &:last-child {
      border-bottom: none;
    }

    .errorItemListTitle {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 28px;
      font-weight: 600;
      color: var(--body-light);
      gap: 8px;
    }

    .rowWrapper {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 28px;
      font-weight: 600;
      color: var(--body-light);
    }

    .errorItemListContent {
      width: 100%;
      display: flex;
      gap: 36px;

      .errorLeftWrapper {
        display: flex;
        flex-direction: column;
        flex: 0.4;

        span {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: var(--body);
        }
      }

      .errorRightWrapper {
        display: flex;
        gap: 2px;
        flex: 0.6;

        .errorList {
          display: flex;
          flex-direction: column;
          gap: 2px;
          padding-top: 5px;
          span {
            font-size: 16px;
            line-height: 28px;
            font-weight: 400;
            color: var(--body-light);
          }
        }
      }
    }
  }
}

