.root {
  position: relative;
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: center;

  .hint {
    display: none;
    position: absolute;
    left: 50%;
    top: -40px;
    width: 100%;
    min-width: 228px;
    background: transparent;
    white-space: nowrap;
    text-align: center;
    z-index: 1;
    transform: translateX(-50%);
    transition: opacity 0.3s;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;

    &.isHovered {
      display: flex !important;
    }
  }
}

.hideElement {
  display: none !important;
}

.iconButton {
  border: none;
  background-color: var(--system-background);
  width: 38px;
  height: 38px;
  padding: 4px;

  svg {
    color: var(--primary);
    width: 24px;
    height: 24px;
  }
}

.isHovered {
  display: flex;
}
