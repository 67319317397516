.leftPanel {
  max-width: 33vw;
  min-width: 400px;
  &.fullScreen {
    min-width: 200px;
  }
  .leftContent {
    padding: 4px 8px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);
    border-radius: 6px;
    background-color: var(--system-background-light);
    gap: 16px;
    display: flex;
    align-items: center;
  }
  .hintWrapper {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    min-width: 0;
    .hint {
      top: 150%
    }
  }
  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 8px;
    border-radius: 4px;
    &:hover {
      background-color: var(--system-text-background);
      cursor: pointer;
    }
  }
  .status {
    flex: 1;
    cursor: pointer;
  }
  .divider {
    width: 1px;
    height: 28px;
    background-color: var(--system-borders);
  }
}

.minimizeElement {
  max-width: fit-content;
  min-width: fit-content;

  .title {
    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }
}
