.container {
  height: 100%;
  .topControls {
    top: 100px;
    margin: 0;
    &.fullScreen {
      top: 20px;
    }
    &.leftPanel {
      left: 100px;
      &.fullScreen {
        left: 20px;
      }
    }
  }
}
