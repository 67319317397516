.selectorContainer {
  display: flex;
  align-items: center;
  position: relative;

  .status {
    cursor: pointer;
    padding: 4px 8px;
    color: var(--system-background-light);
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    border-radius: 6px;
    display: flex;
    gap: 10px;
    align-items: center;
    width: max-content;
    transition: all 0.3s ease;

    &.current {
      background-color: var(--status-active);
    }

    &.in-progress {
      background-color: var(--status-view-edit);
    }

    &.under-review {
      background-color: var(--status-view-only);
    }

    &.ready-for-review {
      background-color: var(--status-invited);
    }

    &.archived {
      background-color: var(--status-draft);
    }

    &.processing {
      background-color: transparent;
      color: var(--body-light);
    }

    &.request-approval {
      background-color: var(--status-request-approval);
    }

    &.approved {
      background-color: var(--status-approved);
    }

    &.requires-rework {
      background-color: var(--status-requires-rework);
    }

    &:hover {
      opacity: 0.8;
    }

    .arrowIcon {
      color: var(--system-background-light);
      transition: transform 0.3s;

      &.active {
        transform: rotate(180deg);
      }
    }
  }

  .processingIcon {
    animation: rotate 2s linear infinite;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .infoIcon {
    color: var(--color-icon-yellow);
    cursor: pointer;
    transition: all 0.3s ease;
    margin-left: 16px;

    &:hover {
      opacity: 0.8;
    }
  }

  .statusDropdown {
    position: absolute;
    width: 210px;
    z-index: 10;
    background-color: var(--system-background-light);
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-shadow: 0 2px 10px 0 var(--status-dropdown-shadow);
    border-radius: 8px;

    &.up {
      bottom: 40px;
    }

    &.down {
      top: 40px;
    }
  }
}
