.container {
  background-color: var(--system-background-light);
  padding: 9px 16px;
  height: 36px;
  border-radius: 40px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);
  align-items: center;
  display: inline-flex;
  gap: 16px;
  p {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    &.text {
      font-weight: 400;
    }
  }
  .list {
    display: inline-flex;
    gap: 20px;
    list-style-type: none;
    li {
      display: flex;
      gap: 8px;
      align-items: center;
      svg {
        &.logical {
          color: var(--value-chain-connection-logical);
        }
        &.financial {
          color: var(--value-chain-connection-financial);
        }
        &.supplierOfGoods {
          color: var(--value-chain-connection-supplier-of-goods);
        }
        &.service {
          color: var(--value-chain-connection-service);
        }
      }
    }
    .text {
      text-transform: capitalize;
    }
  }
}
