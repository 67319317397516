.rightCenterPanel {
  margin: 18vh 20px 0 0;
}

.colorsTip {
  padding-left: 80px;
  z-index: -1;
}

.fullLoader {
  left: 0;
  top: 0;
}

.aloneElement {
  margin: 50vh 20px 0 0;
}
