.modalOverlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: var(--system-background-light);
  border-radius: 8px;
  padding: 24px;
  max-width: 480px;
  width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.2s ease-in-out;
}

.modalTitle {
  color: var(--body);
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.modalBody {
  color: var(--body-light);
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 24px;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;

  & > button {
    width: 45%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
} 